/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

#quiz {

    .not_voted {
        padding: 0 0 10pt 0;
        font-weight: bold;
    }

    progress {
        width: 100%;
        height: dp(3);
        transform: scaleX(-1);
        -webkit-appearance: none;
        appearance: none;
        position:relative;

        &::-webkit-progress-bar {
            background-color: inherit;
        }

        &::-webkit-progress-value {
            //background-color: #d10006;
            background-color: black;
        }
    }

    progress:before {
        content: attr(data-label);
        font-size: 20pt;
        vertical-align: center;
        text-align: center;

        /*Position text over the progress bar */
        transform: scaleX(1);
        position:absolute;
        left:0;
        right:0;
    }


}

#quiz > article {
    &.disabled {
        opacity: unset;
    }

    h3 {
        font-weight: bold;
        text-align: center;
    }

    .data-title-quiz {
        img {
            max-width: 200px;
        }
    }

    .survey-radio {
        border: 0;
        padding: dp(4);

        label {
            animation-duration: 0.2s;
            backface-visibility: hidden;
            padding: dp(16) dp(16) dp(16) dp(16);
            margin: dp(4) 0;
            border-radius: dp(5);
            border: dp(1) solid #999;
            cursor: pointer;
            position: relative;
            transition: padding-top 200ms linear;

            .result_container {
                color: #ffffff;
                font-weight: bold;
                position: absolute;
                width: 100%;
                height: 0;
                top: 0;
                text-align: center;
                left: 0;
                display: block;
                border-top-left-radius: dp(5);
                border-top-right-radius: dp(5);
                transition: all 200ms linear;
                background: rgb(125, 156, 198) !important;
            }

            .result_container_text {
                padding-top: 1px;
                position: relative;
                z-index: 2;
            }

            .result_container_progress {
                position: absolute;
                width:0%;
                left:0;
                top:0;
                height:100%;
                z-index: 1;
                border-top-left-radius: dp(5);
                transition: width 1000ms linear;
                background: #184F99; /* #5213ff; */
            }


            &.show_result {
                padding-top: 40px;
                //&:after {

                .result_container {
                    display: block;
                    height: 30px;
                    padding: 5px 0;
                    /*display: flex;
                    align-items: center;
                    justify-content: center;*/
                }
            }

            &.marked {
                color: white;
                background-color: darkgreen;
                border-color: darkgreen;
            }

            .data-label {
                display: flex;
                img {
                    width: 30%;
                    align-self: center;
                    flex: 0 0 30%;
                    margin-right: 10px;
                }
                div {
                    align-self: center;
                }
            }

            input,
            .checked {
                display: none;
            }

            i {
                flex: 0 0 auto;
            }

            span {
                padding-left: dp(8);
                font-size: 115%;
            }

            @media (pointer: fine) {
                &:hover {
                    background-color: #f9f9f9;
                }
            }

            &.state-checked,
            &.marked {
                .unckecked {
                    display: none;
                }

                .checked {
                    display: initial;
                }
            }

            &.state-checked {
                border-color: black;
                background-color: #333;
                color: white;
                @media (pointer: fine) {
                    &:hover {
                        background-color: #595959;
                    }
                }
            }
        }

        &.grid {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            label {
                width: calc(33.33333% - 5px);
                margin: 5px;
                flex-basis: 30%;
                .data-label {
                    flex-wrap: wrap;
                    justify-content: center;
                    img {
                        width: 50%;
                        flex: 0 0 50%;
                    }
                    div {
                        align-self: center;
                        text-align: center;
                    }
                }
            }
        }
    }

    .quiz-text {
        input[type=text] {
            width: 100%;
            border-radius: dp(5);
            border: dp(1) solid #999;
            padding: dp(8);
        }
    }

    .done {
        display: none;
        margin-top: dp(32);
        text-align: center;
        font-size: 110%;
    }
}
