#wordcloud {
    div {
        span {
            opacity: 0;
            transition: opacity 400ms linear;
            &.show {
                opacity: 1;
            }
        }
    }
}